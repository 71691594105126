<template>
  <v-card
    class="mx-auto overflow-hidden"
  >
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click.stop="clickDrawer()"></v-app-bar-nav-icon>
      <v-menu bottom right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-apps</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(modulo, i) in modulos"
            :key="i"
            link
            v-on:click="getItemsMenu(modulo.id)"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon>{{ modulo.icono }}</v-icon> {{ modulo.txtDesc }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <router-link :to="{ name: 'Home' }">
        <span class="white--text">{{ usuario.cliente }}</span>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn 
        icon
        v-on:click="dialogChat=!dialogChat"
        >
        <v-icon>mdi-chat</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn>
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-cogs</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(setting, i) in settings"
            :key="i"
            link
            @click.native="RunSetting(setting.id)"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon>{{ setting.icono }}</v-icon> {{ setting.txtDesc }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-dialog 
      v-model="dialogChat"
      fullscreen
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Mensajeria</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="dialogChat = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogExit"
      max-width="80%"
    >
      <v-card>
        <v-card-title class="headline">
          Salir del sistema
        </v-card-title>
        <v-card-text>
          ¿Seguro quiere cerrar sesión?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="Salir();"
          >
            Si
          </v-btn>

          <v-btn
            color="error"
            text
            @click="dialogExit = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import axios from 'axios';

  export default {
    name: "Navegacion",
    data() {
      return {
        settings: [
          { id: 1, txtDesc: "Mis Datos", icono: "mdi-card-bulleted-settings" },
          { id: 2, txtDesc: "Salir", icono: "mdi-exit-to-app" },
        ],
        dialogExit: false,
        modulos: [],
        dialogChat: false
      };
    },

    created() {
      this.$vuetify.theme.themes.light = {
        primary: '#2471A3',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      };
      this.getModulos();
      if(this.usuario.idModulo !== null ){
        this.getItemsMenu(this.usuario.idModulo);
      }
    },
    computed: {
      ...mapState(["usuario", "modulo"])
    },
    methods: {
      ...mapActions(["clickDrawer", "cerrarSesion", "setMenu"]),
      async getModulos(){
        try {
          var response = await axios({url: process.env.VUE_APP_API + "parametrizacion/modulos",  method: "POST", headers: { "Accept": "application/json", "Content-Type": "application/json;charset=UTF-8", 'Authorization':  'Bearer '+this.usuario.token}});
          this.modulos = await response.data;
        } catch (error) {
          console.log(error);
        }
      },
      async getItemsMenu(idmodulo){
        try {
          var menu = await axios({url: process.env.VUE_APP_API + "parametrizacion/menus/",  method: "POST", headers: { "Accept": "application/json", "Content-Type": "application/json;charset=UTF-8", 'Authorization': 'Bearer '+this.usuario.token}, data: { idModulo: idmodulo}});
          await this.setMenu(menu.data);
        } catch (error) {
          console.log(error);
        }
      },
      Salir(){
        clearTimeout(this.timeout)
        this.$router.push('/');
        this.cerrarSesion();
      },
      RunSetting(id) {
        switch(id){
          case 1:

          break;
          case 2:
            this.dialogExit = true;
          break;
        }
      },
    },
  };
</script>