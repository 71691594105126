<template>
  <v-app>
    <v-main>
      <div v-if="usuario.isLogin === true">
        <Menu />
        <Navegacion />
      </div>
      <div v-if="usuario.isLogin === false">
        <Login />
      </div>
      <router-view v-if="usuario.isLogin === true" />
      <v-snackbar v-model="alerta.snackbar" :timeout="alerta.timeout" :color="alerta.snackbar_color">
        <p class="text-center">
          {{ alerta.text_snackbar }}
        </p>
      </v-snackbar>
      <v-footer app class="font-weight-medium">
        <v-col class="text-left text-no-wrap" cols="8">
          {{ new Date().getFullYear() }} —
          <v-icon>mdi-heart-pulse</v-icon> HealthSoft | <span class="font-italic caption">HIS-RIS-LIS</span>
        </v-col>
        <v-col class="text-right" cols="4"> </v-col>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
  import { mapActions } from "vuex";
  import Menu from "./components/Menu";
  import Navegacion from "./components/Navegacion";
  import Login from "./components/Login";
  import { mapState } from "vuex";
  import AES from 'crypto-js/aes';
  import ENC from 'crypto-js/enc-utf8';
  import axios from 'axios';

  var key = ENC.parse(process.env.VUE_APP_PRIVATE_KEY);
  var iv = ENC.parse(process.env.VUE_APP_PRIVATE_KEY);

  export default {
    name: "App",
    components: {
      Menu,
      Navegacion,
      Login,
    },
    data: () => ({
      dialogExit: false
    }),
    computed: {
      ...mapState(["usuario", "alerta", "modulo"]),
    },
    created() {
      if(localStorage.getItem("usuario")){
        this.checkUsuario();
      }
      if(localStorage.getItem("drawer")){
        this.setDrawer(localStorage.getItem("drawer"));
      }
    },
    methods: {
      ...mapActions(["setUsuario", "leerUsuario", "cerrarSesion", "setDrawer"]),
      async checkUsuario(){
        try{
          var usuario = await AES.decrypt(localStorage.getItem("usuario"), key, {iv: iv}).toString(ENC);
          await this.leerUsuario(JSON.parse(usuario));
          var response = await axios({url: process.env.VUE_APP_API + "Token/validate",  method: "POST", headers: { "Accept": "application/json", "Content-Type": "application/json;charset=UTF-8", 'Authorization':  'Bearer '+this.usuario.token}});
          if(response.data.token !== 'OK'){
            this.$router.push('/');
            this.cerrarSesion();
          }
        }
        catch(error)
        {
          this.$router.push('/');
          this.cerrarSesion();
        }
      }
    },
  };
</script>
