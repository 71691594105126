<template>
  <div>
    <v-navigation-drawer
      v-model="drawers"
      absolute
      temporary
    >
      <v-row class="mt-2">
        <v-col cols="12">
          <div align="center">
            <v-avatar>
              <img v-if="avatar" alt="Avatar" :src="avatar" />
              <v-icon v-else v-text="mdi - account - multiple"></v-icon>
            </v-avatar>
          </div>
          <p
            class="text-center font-italic caption text-no-wrap"
          >
            <span class="text-capitalize">{{ usuario.nombreCompleto }}</span> ({{ usuario.usuario }})
          </p>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-list dense>
        <template v-for="item in modulo.menu">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.submenu != null"
            :key="item.id"
            v-model="item.model"
            :prepend-icon="item.model ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            append-icon=""
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.txtDesc }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.submenu" :key="i">
              <v-list-item-action v-if="child.icono">
                <v-icon>{{ child.icono }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.txtDesc }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="child.nuevo">
                <router-link
                  v-if="child.linkNuevo.includes('/')"
                  class="routerLink"
                  :to="{
                    name: child.linkNuevo.split('/')[0],
                    params: { id: child.linkNuevo.split('/')[1] },
                  }"
                >
                  <v-icon>mdi-plus</v-icon>
                </router-link>
                <router-link
                  v-else
                  class="routerLink"
                  :to="{ name: child.linkNuevo }"
                >
                  <v-icon>mdi-plus</v-icon>
                </router-link>
              </v-list-item-action>
              <v-list-item-action v-if="child.busqueda">
                <router-link
                  class="routerLink"
                  :to="{ name: child.linkBusqueda }"
                >
                  <v-icon>mdi-magnify</v-icon>
                </router-link>
              </v-list-item-action>
              <v-list-item-action v-if="child.lista">
                <router-link class="routerLink" :to="{ name: child.linkLista }">
                  <v-icon>mdi-view-list</v-icon>
                </router-link>
              </v-list-item-action>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.menu" :to="{ name: item.linkMenu }">
            <v-list-item-action>
              <v-icon>{{ item.icono }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.txtDesc }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="item.busqueda">
              <router-link class="routerLink" :to="{ name: item.linkBusqueda }">
                <v-icon>mdi-magnify</v-icon>
              </router-link>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Menu",
  data() {
    return {
      avatar: require("@/assets/images/avatars/1.png"),
    };
  },
  computed: {
    ...mapState(["modulo", "alerta", "usuario"]),
    drawers: {
      get() {
        return this.modulo.drawer;
      },
      set(value) {
        this.setDrawer(value);
      },
    },
  },
  methods: {
    ...mapActions(["setDrawer"]),
  },
};
</script>