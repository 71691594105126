<template>
    <v-container>
        <v-card
            elevation="3"
            class="mx-auto"
            max-width="80%"
        >
            <v-card-title  class="d-flex align-center justify-center ma-0 pa-0">
                <v-toolbar
                   elevation="0"
                    color="blue darken-3"
                    dark
                    class="ma-0 pa-0"
                >
                    <v-toolbar-title>
                        <v-icon>mdi-heart-pulse</v-icon>
                        <span class="white--text"> HealthSoft</span>
                    </v-toolbar-title>
                </v-toolbar>
            </v-card-title>
            <v-divider class="mx-4"></v-divider>
            <validation-observer
                ref="observer"
                v-slot="{ invalid }"
            >
                <v-form @submit.prevent="submit" autocomplete="off">
                    <v-card-text>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Cliente"
                            rules="required|numeric|digits:4"
                        >
                            <v-text-field
                            v-model="idCliente"
                            :error-messages="errors"
                            label="Cliente"
                            clearable
                            autocomplete="off"
                            :disabled="disabled"
                            ></v-text-field>
                        </validation-provider>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Usuario"
                            rules="required|min:4|max:50"
                        >
                            <v-text-field
                            v-model="login"
                            :error-messages="errors"
                            label="Usuario"
                            type="text"
                            clearable
                            autocomplete="off"
                            :disabled="disabled"
                            ></v-text-field>
                        </validation-provider>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Password"
                            rules="required|min:4|max:50"
                        >
                            <v-text-field
                            v-model="password"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            :error-messages="errors"
                            label="Password"
                            clearable
                            autocomplete="off"
                            :disabled="disabled"
                            @click:append="show1 = !show1"
                            ></v-text-field>
                        </validation-provider>
                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                            <v-col>
                                <v-btn
                                    class="mr-4"
                                    type="submit"
                                    block
                                    plain
                                    :disabled="invalid"
                                >
                                    Ingresar
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn 
                                    block 
                                    plain 
                                    @click="clear"
                                >
                                    Limpiar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-form>
            </validation-observer>
        </v-card>

    </v-container>
</template>

<script>
    import { mapActions, mapState } from "vuex";
    import { required, max, min, numeric, digits } from 'vee-validate/dist/rules'
    import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
    import axios from "axios";
    import AES from 'crypto-js/aes';
    import ENC from 'crypto-js/enc-utf8';

    var key = ENC.parse(process.env.VUE_APP_PRIVATE_KEY);
    var iv = ENC.parse(process.env.VUE_APP_PRIVATE_KEY);
    setInteractionMode('aggressive')

    extend('required', {
    ...required,
    message: '{_field_} no puede quedar vacio',
    })

    extend('max', {
    ...max,
    message: '{_field_} no puede tener más de {length} caractéres',
    })

    extend('min', {
    ...min,
    message: '{_field_} no puede tener menos de {length} caractéres',
    })

    extend('numeric', {
    ...numeric,
    message: '{_field_} debe ser númerico',
    })

    extend('digits', {
    ...digits,
    message: '{_field_} debe tener 4 dígitos',
    })

    export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data: () => ({
        disabled: false,
        idCliente: null,
        login: '',
        password: '',
        token: '',
        show1: false
    }),
    computed: {
        ...mapState(["usuario"])
    },
    methods: {
        ...mapActions(["setUsuario", "setAlerta"]),
        async submit () {
            try {
                this.$refs.observer.validate();
                const userName = AES.encrypt(this.login, key, {iv: iv}).toString();
                const password = AES.encrypt(this.password, key, {iv: iv}).toString();
                var response = await axios({url: process.env.VUE_APP_API + "Token/auth",  method: "POST", headers: { "Accept": "application/json", "Content-Type": "application/json;charset=UTF-8"}, data: { "idCliente": parseInt(this.idCliente), "userName": userName, "password": password }});
                if(response.data.token.message === "OK" && response.data.token.authenticated === true){
                    this.setAlerta({
                        snackbar_color: "success",
                        text_snackbar: 'Bienvenido ' + response.data.nombreCompleto,
                        snackbar: true,
                    });
                    await this.setUsuario( {"id": response.data.id  ,"isLogin": true, "idCliente": response.data.idCliente, "cliente": response.data.cliente, "usuario": response.data.login,  "nombreCompleto": response.data.nombreCompleto, "perfiles": response.data.perfiles, "token": response.data.token.accessToken, "idModulo": response.data.idModulo } );
                    localStorage.setItem("usuario", AES.encrypt(JSON.stringify(this.usuario), key, {iv: iv}).toString());
                }else{
                    this.setAlerta({
                        snackbar_color: "danger",
                        text_snackbar: 'Los datos son incorrectos',
                        snackbar: true,
                    });
                }
            } catch (error) {
                if (error.response.status === 401) {
                    this.setAlerta({
                        snackbar_color: "danger",
                        text_snackbar: 'Los datos son incorrectos',
                        snackbar: true,
                    });
                }else{
                    this.setAlerta({
                        snackbar_color: "danger",
                        text_snackbar: error,
                        snackbar: true,
                    });
                }

            }
        },
        clear () {
            this.idCliente= '';
            this.login= '';
            this.password= '';
            this.$refs.observer.reset();
        },
    },
    }
</script>