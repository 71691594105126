<template>
  <v-container fill-height>
    <v-row class="justify-center align-center">
      <v-col cols="6" sm="4">
        <v-img :src="`${logo}`"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    logo: require("@/assets/images/logos/logo.png"),
  }),
};
</script>
