var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"3","max-width":"80%"}},[_c('v-card-title',{staticClass:"d-flex align-center justify-center ma-0 pa-0"},[_c('v-toolbar',{staticClass:"ma-0 pa-0",attrs:{"elevation":"0","color":"blue darken-3","dark":""}},[_c('v-toolbar-title',[_c('v-icon',[_vm._v("mdi-heart-pulse")]),_c('span',{staticClass:"white--text"},[_vm._v(" HealthSoft")])],1)],1)],1),_c('v-divider',{staticClass:"mx-4"}),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"Cliente","rules":"required|numeric|digits:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Cliente","clearable":"","autocomplete":"off","disabled":_vm.disabled},model:{value:(_vm.idCliente),callback:function ($$v) {_vm.idCliente=$$v},expression:"idCliente"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Usuario","rules":"required|min:4|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Usuario","type":"text","clearable":"","autocomplete":"off","disabled":_vm.disabled},model:{value:(_vm.login),callback:function ($$v) {_vm.login=$$v},expression:"login"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:4|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"error-messages":errors,"label":"Password","clearable":"","autocomplete":"off","disabled":_vm.disabled},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","block":"","plain":"","disabled":invalid}},[_vm._v(" Ingresar ")])],1),_c('v-col',[_c('v-btn',{attrs:{"block":"","plain":""},on:{"click":_vm.clear}},[_vm._v(" Limpiar ")])],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }