import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    alerta: {
      snackbar: false,
      timeout: 1000,
      snackbar_color: '',
      text_snackbar: ''
    },
    usuario: {
      id: null,
      isLogin: false,
      idCliente: null,
      cliente: null,
      usuario: null,
      nombreCompleto: null,
      perfiles: null,
      token: null,
      idModulo: null
    },
    modulo: {
      drawer: false,
      menu: []
    }
  },
  mutations: {
    CLICK_DRAWER(state) {
      state.modulo.drawer = !state.modulo.drawer;
      localStorage.setItem("drawer", state.modulo.drawer);
    },
    SET_DRAWER(state, payload) {
      state.modulo.drawer = payload;
    },
    GET_DRAWER() {
      return this.drawer;
    },
    SET_ALERTA(state, payload) {
      state.alerta = payload;
    },
    SET_USUARIO(state, payload) {
      state.usuario = payload;
    },
    SET_MENU(state, payload) {
      state.modulo.menu = payload;
    }
  },
  actions: {
    clickDrawer({ commit }) {
      commit('CLICK_DRAWER');
    },
    setDrawer: ({ commit }, payload) => {
      commit('SET_DRAWER', payload)
    },
    setAlerta: ({ commit }, payload) => {
      commit('SET_ALERTA', payload)
    },
    setUsuario: ({ commit }, payload) => {
      commit('SET_USUARIO', payload)
    },
    leerUsuario: ({ commit }, payload) => {
      commit('SET_USUARIO', payload)
    },
    cerrarSesion: ({ commit }) => {
      commit('SET_USUARIO', {
        id: null,
        isLogin: false,
        idCliente: null,
        cliente: null,
        usuario: null,
        nombreCompleto: null,
        perfiles: null,
        token: null,
        idModulo: null
      });
      localStorage.removeItem("usuario");
    },
    setMenu: ({ commit }, payload) => {
      commit('SET_MENU', payload)
    }
  }
})
