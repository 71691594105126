import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store/index'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/datos',
    name: 'Datos',
    component: () => import(/* webpackChunkName: "datos" */ '../views/parametrizacion/Datos.vue'),
    meta: {rutaProtegida: true}
  },
  {
    path: '/parametrizacion/pais',
    name: 'AgregaPais',
    component: () => import(/* webpackChunkName: "pais" */ '../views/parametrizacion/Pais.vue')
  },
  {
    path: '/parametrizacion/paises',
    name: 'Paises',
    component: () => import(/* webpackChunkName: "paises" */ '../views/parametrizacion/Paises.vue')
  },
  {
    path: '/parametrizacion/provincia',
    name: 'AgragaProvincia',
    component: () => import(/* webpackChunkName: "provincia" */ '../views/parametrizacion/Provincia.vue')
  },
  {
    path: '/parametrizacion/Provincias',
    name: 'Provincias',
    component: () => import(/* webpackChunkName: "provincias" */ '../views/parametrizacion/Provincias.vue')
  },
  {
    path: '/parametrizacion/Localidad',
    name: 'AgregaLocalidad',
    component: () => import(/* webpackChunkName: "localidad" */ '../views/parametrizacion/Localidad.vue')
  },
  {
    path: '/parametrizacion/Localidades',
    name: 'Localidades',
    component: () => import(/* webpackChunkName: "localidades" */ '../views/parametrizacion/Localidades.vue')
  },
  {
    path: '/parametrizacion/Perfil',
    name: 'AgregaPerfil',
    component: () => import(/* webpackChunkName: "perfil" */ '../views/parametrizacion/Perfil.vue')
  },
  {
    path: '/parametrizacion/Perfiles',
    name: 'Perfiles',
    component: () => import(/* webpackChunkName: "perfiles" */ '../views/parametrizacion/Perfiles.vue')
  },
  {
    path: '/Personas/Persona',
    name: 'Persona',
    component: () => import(/* webpackChunkName: "persona" */ '../views/personas/Persona.vue')
  },
  {
    path: '/Personas',
    name: 'Personas',
    component: () => import(/* webpackChunkName: "persona" */ '../views/personas/Personas.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) =>{
  const rutaEsProtegida = to.matched.some(item => item.meta.rutaProtegida);
  if(rutaEsProtegida && Store.state.usuario.isLogin === null){
    next({name: 'Home'});
  }else{
    next();
  }
});

export default router
